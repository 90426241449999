<template>
  <v-card class="d-flex justify-center align-center flex-column">
    <v-card-title class="headline">{{ CancelTripMsg }}</v-card-title>

    <v-card-text>
      <v-row class="text-center w-full d-flex justify-center">
        {{ SelectMsg }}.
      </v-row>
      <v-row class="justify-center">
        <v-col cols="6">
          <v-radio-group v-model="selectedReason" :mandatory="true">
            <v-radio
              v-for="reason in reasons"
              :key="reason.unique_id"
              :label="lang === 'es' ? reason.name_spanish : reason.name_english"
              :value="reason.name_english"
            >
            </v-radio>
          </v-radio-group>
          <v-text-field
            v-if="selectedReason === 'Other'"
            v-model="customReason"
            label="Ingrese su razón de cancelación"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="d-flex justify-center align-center">
      <v-spacer></v-spacer>
      <v-btn color="white" block @click="goBack">
        {{ CancelMsg }}
      </v-btn>
      <v-btn color="primary" :disabled="selectedReason==='Other' && !customReason" block @click="confirmCancel">
        {{ ConfirmMsg }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "@/plugins/axios";
import { mapActions } from "vuex";

export default {
  props: {
    value: Boolean,
    app_type: Number,
    CancelTripMsg: String,
    ConfirmMsg: String,
    SelectMsg: String,
    CancelMsg: String,
    lang: String,
  },
  data() {
    return {
      dialog: this.value,
      selectedReason: null,
      customReason: "",
      reasons: [
        {
          name_spanish: "Otro",
          name_english: "Other",
          unique_id: 0,
        },
      ],
    };
  },
  watch: {
    value(val) {
      this.dialog = val;
    },
    dialog(val) {
      this.$emit("input", val);
    },
  },
  mounted() {
    this.fetchCancellationReasons();
  },
  methods: {
    ...mapActions(["setLoading"]),
    async fetchCancellationReasons() {
      try {
        this.setLoading(true);
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/cancellation_reasons?app_type=${this.app_type}`
        );
        // add new reasons but keep other at the end
        this.reasons = response.data?.reasons.concat(this.reasons);
      } catch (error) {
        console.error("Error fetching cancellation reasons:", error);
        this.$dialog.notify.error(
          this.$t("Error fetching cancellation reasons")
        );
      } finally {
        this.setLoading(false);
      }
    },
    confirmCancel() {
      if (this.selectedReason) {
        const reason =
        this.selectedReason === "Other"
        ? this.customReason
        : this.selectedReason;
        this.$emit("submit", reason);
      }
    },
    goBack() {
      this.$emit("submit", null);
    },
  },
};
</script>
